import request from './request.js';
import public_request from './public_request'
//获取字典值列表
export function dictionaryValuelist({dictionaryId,pageNo,pageSize}) {
    return request({
        url: '/dictionary-management/dictionary/values',
        method: 'get',
        params: public_request({
            dictionaryId,
            pageNo,
            pageSize
        })
    })
}
//新增字典值
export function dictionaryValueAddlist({dictionaryId,cnVal,enVal,number,enable,remarks}) {
    return request({
        url: '/dictionary-management/dictionary/value',
        method: 'post',
        data: public_request({
            dictionaryId,
            cnVal,
            enVal,
            number,
            enable,
            remarks
        })
    })
}
//编辑字典值
export function dictionaryValueEditlist({dictionaryValueId,cnVal,enVal,number,enable,remarks}) {
    return request({
        url: '/dictionary-management/dictionary/value',
        method: 'put',
        data: public_request({
            dictionaryValueId,
            cnVal,
            enVal,
            number,
            enable,
            remarks
        })
    })
}
//删除字典值
export function dictionaryValueDeletelist({dictionaryValueId}) {
    return request({
        url: '/dictionary-management/dictionary/value',
        method: 'delete',
        data: public_request({
            dictionaryValueId
        })
    })
}
//设置字典值启用状态
export function dictionaryValueSwitchlist({dictionaryValueId,enable}) {
    return request({
        url: '/dictionary-management/dictionary/value-enable',
        method: 'put',
        data: public_request({
            dictionaryValueId,
            enable
        })
    })
}