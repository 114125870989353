import request from './request.js';
import public_request from './public_request'
//获取字典列表
export function dictionarylist({pageNo,pageSize}) {
    return request({
        url: '/dictionary-management/dictionaries',
        method: 'get',
        params: public_request({
            pageNo,
            pageSize
        })
    })
}
//新增字典
export function dictionaryAddlist({name,code,enable,remarks}) {
    return request({
        url: '/dictionary-management/dictionary',
        method: 'post',
        data: public_request({
            name,
            code,
            enable,
            remarks
        })
    })
}
//编辑字典
export function dictionaryEditlist({dictionaryId,name,code,enable,remarks}) {
    return request({
        url: '/dictionary-management/dictionary',
        method: 'put',
        data: public_request({
            dictionaryId,
            name,
            code,
            enable,
            remarks
        })
    })
}
//删除字典
export function dictionaryDeletelist({dictionaryId}) {
    return request({
        url: '/dictionary-management/dictionary',
        method: 'delete',
        data: public_request({
            dictionaryId
        })
    })
}
//设置字典启用状态
export function dictionarySwitchlist({dictionaryId,enable}) {
    return request({
        url: '/dictionary-management/dictionary-enable',
        method: 'put',
        data: public_request({
            dictionaryId,
            enable
        })
    })
}