<template>
  <div>
    <h3 style="text-align:center;margin-top:20px">
      字典管理
    </h3>
    <el-card style="margin:20px;height:calc(100vh - 150px)">
      <el-row :gutter="0">
        <!-- 字典类型模块 -->

        <el-col :span="12">
          <!-- <el-card class="box-card"> -->
          <!-- <h3>字典类型</h3> -->
          <p style="text-align:left;margin-bottom:15px">
            <span style="margin-right:10px">字典列表</span>
            <el-button
              type="primary"
              @click="dialogTableVisible = true"
            >
              添加字典
            </el-button>
          </p>
          <!-- 新增字典类型弹框 -->
          <el-drawer
            :title="ruleForm.id ? '编辑字典' : '添加字典'"
            :visible.sync="dialogTableVisible"
            direction="rtl"
            size="683px"
            :before-close="resetForm"
          >
            <div class="drawer-content">
              <el-form
                ref="ruleForm"
                :model="ruleForm"
                :rules="rules"
                label-width="100px"
                class="demo-ruleForm"
                label-position="top"
              >
                <el-form-item
                  label="字典名称"
                  prop="name"
                >
                  <el-input
                    v-model="ruleForm.name"
                    maxlength="32"
                    show-word-limit
                  />
                </el-form-item>
                <el-form-item
                  label="代码"
                  prop="code"
                >
                  <el-input
                    v-model="ruleForm.code"
                    maxlength="32"
                    show-word-limit
                  />
                </el-form-item>
                <el-form-item
                  label="备注"
                  prop="remarks"
                >
                  <el-input
                    v-model="ruleForm.remarks"
                    type="textarea"
                    maxlength="128"
                    show-word-limit
                  />
                </el-form-item>
              </el-form>
              <div class="drawer-footer">
                <el-button
                  type="primary"
                  @click="submitForm"
                >
                  保存
                </el-button>
                <el-button @click="resetForm">
                  取消
                </el-button>
              </div>
            </div>
          </el-drawer>
          <!-- <el-dialog title="添加新字典类型" :visible.sync="dialogTableVisible">
            <el-form
              :model="ruleForm"
              :rules="rules"
              ref="ruleForm"
              label-width="100px"
              class="demo-ruleForm"
            >
              <el-form-item label="字典名称" prop="name">
                <el-input
                  v-model="ruleForm.name"
                  maxlength="32"
                  show-word-limit
                ></el-input>
              </el-form-item>
              <el-form-item label="代码" prop="code">
                <el-input
                  v-model="ruleForm.code"
                  maxlength="32"
                  show-word-limit
                ></el-input>
              </el-form-item>
              <el-form-item label="备注" prop="remarks">
                <el-input
                  type="textarea"
                  maxlength="128"
                  show-word-limit
                  v-model="ruleForm.remarks"
                ></el-input>
              </el-form-item>
              <el-form-item>
                <el-button type="primary" @click="submitForm('ruleForm')"
                  >保存</el-button
                >
                <el-button @click="resetForm('ruleForm')">取消</el-button>
              </el-form-item>
            </el-form>
          </el-dialog> -->
          <!-- 字典类型列表渲染 -->
          <el-table
            v-loading="loading"
            v-tableHeight="{ bottomOffset: 90 }"
            :data="tableData"
            height="100px"
            border
            style="width: 100%"
            highlight-current-row
            :header-cell-style="styleObj"
            @row-click="rowc"
          >
            <el-table-column
              prop="name"
              label="名字"
            />
            <el-table-column
              prop="code"
              label="代码"
            />
            <el-table-column label="是否启用">
              <template slot-scope="scope">
                <el-switch
                  v-model="scope.row.enabled"
                  :active-value="1"
                  :inactive-value="0"
                  @change="changeStatus($event, scope.row)"
                />
              </template>
            </el-table-column>
            <el-table-column
              prop="remarks"
              label="备注"
            />
            <el-table-column
              prop
              label="操作"
            >
              <template slot-scope="scope">
                <div v-if="scope.row.fixed == 0">
                  <el-button
                    type="text"
                    size="medium"
                    @click="handleClick(scope.$index, scope.row)"
                  >
                    编辑
                  </el-button>
                  <el-popconfirm
                    title="确定要删除这个字典类型吗？"
                    @confirm="remove(scope.$index, scope.row)"
                  >
                    <el-button
                      slot="reference"
                      type="text"
                      size="medium"
                      style="color:red;margin-left:10px"
                    >
                      删除
                    </el-button>
                  </el-popconfirm>
                </div>
              </template>
            </el-table-column>
          </el-table>
          <!-- 字典类型列表编辑按钮弹框 -->
          <!-- <el-dialog title="编辑字典" :visible.sync="dialogTableVisible1">
            <el-form
              :model="editForm"
              :rules="rules"
              ref="editForm"
              label-width="100px"
              class="demo-ruleForm"
            >
              <el-form-item label="字典名称" prop="name">
                <el-input
                  v-model="editForm.name"
                  maxlength="32"
                  show-word-limit
                ></el-input>
              </el-form-item>
              <el-form-item label="代码" prop="code">
                <el-input
                  v-model="editForm.code"
                  maxlength="32"
                  show-word-limit
                ></el-input>
              </el-form-item>
              <el-form-item label="备注" prop="remarks">
                <el-input
                  type="textarea"
                  maxlength="128"
                  show-word-limit
                  v-model="editForm.remarks"
                ></el-input>
              </el-form-item>
              <el-form-item>
                <el-button type="primary" @click="submitForm1('editForm')"
                  >保存</el-button
                >
                <el-button @click="dialogTableVisible1 = false">取消</el-button>
              </el-form-item>
            </el-form>
          </el-dialog> -->
          <!-- 字典类型列表分页 -->
          <div
            class="block"
            style="margin-top:20px;text-align:right"
          >
            <el-pagination
              :current-page="page"
              :page-size="size"
              layout="total, sizes, prev, pager, next, jumper"
              :total="len"
              @size-change="handleSizeChange"
              @current-change="handleCurrentChange"
            />
          </div>
          <!-- </el-card> -->
        </el-col>
        <!-- 字典值模块 -->
        <el-col :span="12">
          <!-- <el-card class="box-card"> -->
          <!-- <h3>
            字典值/
            <span>{{ title }}</span>
          </h3> -->
          <p style="text-align:left;margin-bottom:15px">
            <span style="margin-right:10px">字典值列表</span>
            <el-button
              type="primary"
              @click="dialogTableVisible2 = true"
            >
              添加字典值
            </el-button>
          </p>
          <!-- 新增字典值弹框 -->
          <el-drawer
            :title="ruleForm1.id ? '编辑字典值' : '添加字典值'"
            :visible.sync="dialogTableVisible2"
            direction="rtl"
            size="683px"
            :before-close="resetForm2"
          >
            <div class="drawer-content">
              <el-form
                ref="ruleForm1"
                :model="ruleForm1"
                :rules="rules1"
                label-width="100px"
                class="demo-ruleForm"
                label-position="top"
              >
                <el-form-item
                  label="数字代码"
                  prop="number"
                >
                  <el-input
                    v-model.number="ruleForm1.number"
                    maxlength="32"
                    show-word-limit
                    oninput="value=value.replace(/[^\d]/g,'')"
                  />
                </el-form-item>
                <el-form-item
                  label="中文值"
                  prop="cnVal"
                >
                  <el-input
                    v-model="ruleForm1.cnVal"
                    maxlength="32"
                    show-word-limit
                  />
                </el-form-item>
                <el-form-item
                  label="英文值"
                  prop="enVal"
                >
                  <el-input
                    v-model="ruleForm1.enVal"
                    maxlength="32"
                    show-word-limit
                  />
                </el-form-item>
                <el-form-item
                  label="备注"
                  prop="remarks"
                >
                  <el-input
                    v-model="ruleForm1.remarks"
                    type="textarea"
                    maxlength="128"
                    show-word-limit
                  />
                </el-form-item>
              </el-form>
              <div class="drawer-footer">
                <el-button
                  type="primary"
                  @click="submitForm2"
                >
                  保存
                </el-button>
                <el-button @click="resetForm2">
                  取消
                </el-button>
              </div>
            </div>
          </el-drawer>
          <!-- <el-dialog title="添加新字典值" :visible.sync="dialogTableVisible2">
            <el-form
              :model="ruleForm1"
              :rules="rules1"
              ref="ruleForm1"
              label-width="100px"
              class="demo-ruleForm"
            >
              <el-form-item label="数字代码" prop="number">
                <el-input
                  v-model.number="ruleForm1.number"
                  maxlength="32"
                  show-word-limit
                  oninput="value=value.replace(/[^\d]/g,'')"
                ></el-input>
              </el-form-item>
              <el-form-item label="中文值" prop="cnVal">
                <el-input
                  v-model="ruleForm1.cnVal"
                  maxlength="32"
                  show-word-limit
                ></el-input>
              </el-form-item>
              <el-form-item label="英文值">
                <el-input
                  v-model="ruleForm1.enVal"
                  maxlength="32"
                  show-word-limit
                ></el-input>
              </el-form-item>
              <el-form-item label="备注" prop="remarks">
                <el-input
                  type="textarea"
                  maxlength="128"
                  show-word-limit
                  v-model="ruleForm1.remarks"
                ></el-input>
              </el-form-item>
              <el-form-item>
                <el-button type="primary" @click="submitForm2('ruleForm1')"
                  >保存</el-button
                >
                <el-button @click="resetForm2('ruleForm1')">取消</el-button>
              </el-form-item>
            </el-form>
          </el-dialog> -->
          <!-- 字典值列表渲染 -->
          <el-table
            v-loading="loading1"
            v-tableHeight="{ bottomOffset: 90 }"
            :data="tableData1"
            height="100px"
            :header-cell-style="styleObj"
            border
            style="width: 100%"
          >
            <el-table-column
              prop="number"
              label="数字代码"
            />
            <el-table-column
              prop="cnVal"
              label="中文值"
            />
            <el-table-column
              prop="enVal"
              label="英文值"
            />
            <el-table-column label="是否启用">
              <template slot-scope="scope">
                <el-switch
                  v-model="scope.row.enabled"
                  :active-value="1"
                  :inactive-value="0"
                  @change="changeStatus1($event, scope.row)"
                />
              </template>
            </el-table-column>
            <el-table-column
              prop="remarks"
              label="备注"
            />
            <el-table-column
              prop
              label="操作"
            >
              <template slot-scope="scope">
                <div v-if="scope.row.fixed == 0">
                  <el-button
                    type="text"
                    size="medium"
                    @click="handleClick1(scope.$index, scope.row)"
                  >
                    编辑
                  </el-button>
                  <el-popconfirm
                    title="确定要删除这个字典值吗？"
                    @confirm="remove1(scope.$index, scope.row)"
                  >
                    <el-button
                      slot="reference"
                      type="text"
                      size="medium"
                      style="color:red;margin-left:10px"
                    >
                      删除
                    </el-button>
                  </el-popconfirm>
                </div>
              </template>
            </el-table-column>
          </el-table>
          <!-- 字典值列表编辑按钮弹框 -->
          <!-- <el-dialog title="编辑字典" :visible.sync="dialogTableVisible3">
            <el-form
              :model="editForm1"
              :rules="rules1"
              ref="editForm1"
              label-width="100px"
              class="demo-ruleForm"
            >
              <el-form-item label="数字代码" prop="number">
                <el-input
                  v-model="editForm1.number"
                  maxlength="32"
                  show-word-limit
                ></el-input>
              </el-form-item>
              <el-form-item label="中文值" prop="cnVal">
                <el-input
                  v-model="editForm1.cnVal"
                  maxlength="32"
                  show-word-limit
                ></el-input>
              </el-form-item>
              <el-form-item label="英文值">
                <el-input
                  v-model="editForm1.enVal"
                  maxlength="32"
                  show-word-limit
                ></el-input>
              </el-form-item>
              <el-form-item label="备注" prop="remarks">
                <el-input
                  type="textarea"
                  maxlength="128"
                  show-word-limit
                  v-model="editForm1.remarks"
                ></el-input>
              </el-form-item>
              <el-form-item>
                <el-button type="primary" @click="submitForm3('editForm1')"
                  >保存</el-button
                >
                <el-button @click="dialogTableVisible3 = false">取消</el-button>
              </el-form-item>
            </el-form>
          </el-dialog> -->
          <div
            class="block"
            style="margin-top:20px;text-align:right"
          >
            <el-pagination
              :current-page="page1"
              :page-size="size1"
              layout="total, sizes, prev, pager, next, jumper"
              :total="len1"
              @size-change="handleSizeChange1"
              @current-change="handleCurrentChange1"
            />
          </div>
          <!-- </el-card> -->
        </el-col>
      </el-row>
    </el-card>
  </div>
</template>
<script>
import {
  dictionarylist,
  dictionaryAddlist,
  dictionaryEditlist,
  dictionaryDeletelist,
  dictionarySwitchlist,
} from "../../api/dictionary.js";
import {
  dictionaryValuelist,
  dictionaryValueAddlist,
  dictionaryValueEditlist,
  dictionaryValueDeletelist,
  dictionaryValueSwitchlist,
} from "../../api/dictionaryValue.js";
export default {
  data() {
    return {
      styleObj: {
        background: "#f5f7fa",
      },
      tableData: [],
      dialogTableVisible: false,
      ruleForm: {
        name: "",
        code: "",
        remarks: "",
        enable: 1,
      },
      rules: {
        name: [
          { required: true, message: "请输入字典名称", trigger: "blur" },
          { min: 1, max: 32, message: "长度最多是32个字符", trigger: "blur" },
          { validator: this.formValidation.pagename, trigger: "blur" },
        ],
        code: [
          { required: true, message: "请输入代码", trigger: "blur" },
          { min: 1, max: 32, message: "长度最多是32个字符", trigger: "blur" },
          { validator: this.formValidation.code, trigger: "blur" },
        ],
      },
      dialogTableVisible1: false,
      editForm: "",
      page: 1,
      len: 0,
      size: 10,
      tableData1: [],
      ruleForm1: {
        number: "",
        cnVal: "",
        enVal: "",
        enable: 1,
        remarks: "",
      },
      rules1: {
        number: [
          { required: true, message: "请输入数字代码", trigger: "blur" },
          { validator: this.formValidation.numcode, trigger: "blur" },
        ],
        cnVal: [
          { required: true, message: "请输入中文值", trigger: "blur" },
          { validator: this.formValidation.chine, trigger: "blur" },
        ],

        enVal: [
          { validator: this.formValidation.englishName, trigger: "blur" },
        ],
      },
      dialogTableVisible2: false,
      dialogTableVisible3: false,
      editForm1: "",
      title: "",
      dataVal: "",
      loading: true,
      loading1: true,
      page1: 1,
      len1: 0,
      size1: 10,
    };
  },
  mounted() {
    //字典类型渲染
    this.getDictionarylist();
  },
  methods: {
    getDictionarylist() {
      dictionarylist({ pageNo: this.page, pageSize: this.size }).then((res) => {
        this.tableData = res.data.list;
        this.len = res.data.total;
        this.title = this.tableData[0].name;
        this.dataVal = this.tableData[0];
        this.dictionaryValue(this.tableData[0].id);
        this.loading = false;
      });
    },
    //渲染字典值的事件
    dictionaryValue(id) {
      dictionaryValuelist({
        dictionaryId: id,
        pageNo: this.page1,
        pageSize: this.size1,
      }).then((res) => {
        this.tableData1 = res.data.list;
        this.len1 = res.data.total;
        this.loading1 = false;
      });
    },
    //两个列表的联动
    rowc(row) {
      this.title = row.name;
      this.dataVal = row;
      this.dictionaryValue(this.dataVal.id);
    },
    //新增字典类型弹框中的保存事件
    submitForm() {
      this.$refs.ruleForm.validate((valid) => {
        if (valid) {
          if (this.ruleForm.id) {
            // this.editForm.dictionaryId = this.editForm.id;
            let data = {
              dictionaryId: this.ruleForm.id,
              name: this.ruleForm.name,
              code: this.ruleForm.code,
              enable: 1,
              remarks: this.ruleForm.remarks,
            };
            dictionaryEditlist(data).then((res) => {
              if (res.data == 0) {
                // this.dialogTableVisible = false;
                this.resetForm();
                dictionarylist({ pageNo: this.page, pageSize: this.size }).then(
                  (res) => {
                    this.tableData = res.data.list;
                    this.len = res.data.total;
                  }
                );
                this.$message({
                  message: "保存成功",
                  type: "success",
                });
              } else {
                this.$message.error(res.error);
              }
            });
          } else {
            let data = {
              name: this.ruleForm.name,
              code: this.ruleForm.code,
              enable: 1,
              remarks: this.ruleForm.remarks,
            };
            dictionaryAddlist(data).then((res) => {
              if (res.code == 0) {
                // this.dialogTableVisible = false;
                this.resetForm();
                dictionarylist({ pageNo: this.page, pageSize: this.size }).then(
                  (res) => {
                    this.tableData = res.data.list;
                    this.len = res.data.total;
                  }
                );
                this.$message({
                  message: "添加成功",
                  type: "success",
                });
                this.ruleForm = {
                  name: "",
                  code: "",
                  ramarks: "",
                  enable: 1,
                };
              } else {
                this.$message.error(res.error);
              }
            });
          }
        } else {
          this.$message.error("请检查输入框中的内容");
          return false;
        }
      });
    },
    //新增字典类型弹框中的取消事件
    resetForm() {
      this.ruleForm.id = "";
      this.$refs.ruleForm.resetFields();
      this.dialogTableVisible = false;
    },
    //点击字典类型列表中的编辑按钮事件
    handleClick(index, row) {
      this.dialogTableVisible = true;
      let _row = row;
      this.$nextTick(() => {
        this.ruleForm = Object.assign({}, _row);
      });
      // this.editForm = Object.assign({}, _row); // editForm是Dialog弹框的data
    },
    //删除字典类型列表的事件
    remove(index, row) {
      dictionaryDeletelist({ dictionaryId: row.id }).then(() => {
        dictionarylist({ pageNo: this.page, pageSize: this.size }).then(
          (res) => {
            this.tableData = res.data.list;
            this.len = res.data.total;
            this.$message({
              message: "删除成功",
              type: "success",
            });
          }
        );
      });
      this.tableData1 = [];
      this.title = "";
      this.dataVal = "";
    },
    //字典类型开关状态控制
    changeStatus(e, row) {
      // console.log(row);
      this.dataVal = row;
      let zt = "";
      if (row.enabled === 0) {
        zt = 0;
      } else {
        zt = 1;
      }
      dictionarySwitchlist({
        dictionaryId: row.id,
        enable: zt,
      }).then((res) => {
        if (res.code == 0) {
          this.$message.success(zt == 0 ? "禁用成功" : "启用成功");
        } else {
          this.$message.error(res.message);
        }
        dictionarylist({ pageNo: this.page, pageSize: this.size }).then(
          (res) => {
            this.tableData = res.data.list;
            this.len = res.data.total;
            this.title = this.tableData[0].name;
            this.dictionaryValue(this.dataVal.id);
            this.loading = false;
          }
        );
      });
    },
    //字典类型分页的事件
    handleSizeChange(val) {
      this.size = val;
      dictionarylist({ pageNo: this.page, pageSize: this.size }).then((res) => {
        this.tableData = res.data.list;
      });
    },
    handleCurrentChange(val) {
      this.page = val;
      dictionarylist({ pageNo: this.page, pageSize: this.size }).then((res) => {
        this.tableData = res.data.list;
      });
    },
    //添加新字典值弹框中的保存事件
    submitForm2() {
      this.$refs.ruleForm1.validate((valid) => {
        if (valid) {
          if (this.ruleForm1.id) {
            // this.editForm1.dictionaryValueId = this.editForm1.id;
            let data = {
              dictionaryValueId: this.ruleForm1.id,
              number: this.ruleForm1.number,
              cnVal: this.ruleForm1.cnVal,
              enVal: this.ruleForm1.enVal,
              enable: 1,
              remarks: this.ruleForm1.remarks,
            };
            dictionaryValueEditlist(data).then((res) => {
              if (res.code == 0) {
                // this.dialogTableVisible3 = false;
                this.resetForm2();
                this.dictionaryValue(this.dataVal.id);
                this.$message({
                  message: "保存成功",
                  type: "success",
                });
              } else {
                this.$message.error(res.error);
              }
            });
          } else {
            this.ruleForm1.dictionaryId = this.dataVal.id;
            if (this.ruleForm1.dictionaryId == undefined) {
              this.$message.error("请选择字典类型");
            } else {
              dictionaryValueAddlist(this.ruleForm1).then((res) => {
                if (res.code == 0) {
                  // this.dialogTableVisible2 = false;
                  this.resetForm2();
                  this.dictionaryValue(this.dataVal.id);
                  this.$message({
                    message: "添加成功",
                    type: "success",
                  });
                  this.ruleForm1 = {
                    number: "",
                    cnVal: "",
                    enVal: "",
                    enable: 1,
                    remarks: "",
                  };
                } else {
                  this.$message.error(res.error);
                }
              });
            }
          }
        } else {
          this.$message.error("请检查输入框中的内容");
          return false;
        }
      });
    },
    //添加新字典值弹框中的取消事件
    resetForm2() {
      this.ruleForm1.id = "";
      this.$refs.ruleForm1.resetFields();
      this.dialogTableVisible2 = false;
    },
    //点击字典值列表中的编辑按钮事件
    handleClick1(index, row) {
      this.dialogTableVisible2 = true;
      let _row = row;
      this.$nextTick(() => {
        this.ruleForm1 = Object.assign({}, _row);
      });
    },
    //字典值弹框中的保存事件
    // submitForm3(editForm) {
    //   this.$refs[editForm].validate((valid) => {
    //     if (valid) {
    //       this.editForm1.dictionaryValueId = this.editForm1.id;
    //       dictionaryValueEditlist(this.editForm1).then((res) => {
    //         if (res.code == 0) {
    //           this.dialogTableVisible3 = false;
    //           this.dictionaryValue(this.dataVal.id);
    //           this.$message({
    //             message: "保存成功",
    //             type: "success",
    //           });
    //         } else {
    //           this.$message.error(res.error);
    //         }
    //       });
    //     } else {
    //       this.$message.error("请检查输入框中的内容");
    //       return false;
    //     }
    //   });
    // },
    //删除字典值列表的事件
    remove1(index, row) {
      dictionaryValueDeletelist({ dictionaryValueId: row.id }).then(() => {
        this.dictionaryValue(this.dataVal.id);
      });
      this.$message({
        message: "删除成功",
        type: "success",
      });
    },
    handleSizeChange1(val) {
      this.size1 = val;
      this.dictionaryValue(this.dataVal.id);
    },
    handleCurrentChange1(val) {
      this.page1 = val;
      this.dictionaryValue(this.dataVal.id);
    },
    //字典值开关状态控制
    changeStatus1(e, row) {
      console.log(row);
      let zt = "";
      if (row.enabled === 0) {
        zt = 0;
      } else {
        zt = 1;
      }
      dictionaryValueSwitchlist({
        dictionaryValueId: row.id,
        enable: zt,
      }).then((res) => {
        if (res.code == 0) {
          this.$message.success(zt == 0 ? "禁用成功" : "启用成功");
        } else {
          this.$message.error(res.message);
        }
        this.dictionaryValue(this.dataVal.id);
      });
    },
  },
};
</script>
<style lang="less" scoped>
::v-deep .el-col-12 {
  // text-align: center;
  padding: 20px;
}
::v-deep .el-dialog {
  width: 25%;
  min-width: 400px;
}
::v-deep .el-card__body {
  padding: 0;
}
/deep/ .el-form-item {
  margin-bottom: 18px;
}
/deep/ .el-form-item__label {
  line-height: 0;
}
/deep/ .el-drawer:focus {
  outline: none;
}
/deep/ .el-drawer__header {
  margin: 0;
  padding: 0;
  margin: 20px 20px 0px 20px;
  padding-bottom: 20px;
  border-bottom: 1px solid #ccc;
  // padding: 0;
  span {
    outline: none;
    font-weight: 600;
    color: #000;
  }
}
/deep/ .el-drawer__body {
  overflow-y: scroll;
}
</style>
